<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-text>
          <b-row>
            <b-col
              cols="12"
            >
              <h6 class="mb-1">
                Güncelle
              </h6>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group label="Form Kodu">
                <b-form-input
                  v-model="formData.formcode"
                  placeholder="Form Kodu"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group label="Başlık">
                <b-form-input
                  v-model="formData.title"
                  placeholder="Başlık"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Reklam ID">
                <b-form-input
                  v-model="formData.ads_id"
                  placeholder="Reklam ID"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Bağlantı">
                <b-form-input
                  v-model="formData.url"
                  placeholder="Bağlantı"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
      <b-button
        variant="primary"
        @click="submitForm"
      >
        Kaydet
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardText, BRow, BCol, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'Edit',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        id: null,
        formcode: null,
        title: null,
        ads_id: null,
      },
    }
  },
  computed: {
    adFormData() {
      return this.$store.getters['marketingAdForms/getMarketing_ad_form']
    },
    saveStatus() {
      return this.$store.getters['marketingAdForms/getMarketing_ad_formSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
    adFormData(val) {
      this.formData = val
    },
  },
  created() {
    this.getData()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getData() {
      this.$store.dispatch('marketingAdForms/marketing_ad_formView', this.$route.params.id)
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('marketingAdForms/marketing_ad_formSave', this.formData)
    },
  },
}
</script>
